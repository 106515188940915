const stepsDTO = (
  allStrapiGuideStepItems,
  strapiGuideSteps,
  strapiGetAQuoteV2,
  strapiRessource,
  strapiTranslation,
  pageContext
) => {
  const mapSteps = (steps, substeps) =>
    steps.guide.map(c => {
      return {
        ...c,
        guide_step_items: c.guide_step_items
          .map(item => substeps.nodes.find(el => el.strapiId === item.id))
          .sort((a, b) => a.weight - b.weight),
      }
    })

  const guide = {
    title: strapiGuideSteps.title,
    meta: strapiGuideSteps.meta,
    steps: mapSteps(strapiGuideSteps, allStrapiGuideStepItems),
  }

  const subLvl = (lvl, id = null) => {
    // console.log("sub lvl ", lvl)
    return {
      lvl: lvl,
      items: [],
      id: id,
    }
  }

  let menu = {}
  let checkList = []
  let advisor = {
    advisorList: strapiGuideSteps.advisor,
    slug: strapiGetAQuoteV2?.slug,
    footerLogo: pageContext.general.allStrapiGeneral.nodes[0]?.footerLogo,
    advisorMenu: strapiRessource,
  }
  let guideFooter = {
    footer: strapiGuideSteps.footer,
    translationLabels: strapiTranslation.form,
  }
  guide.steps.map((step, index1) => {
    step.guide_step_items.map((stepItem, index2) => {
      stepItem.guideContent.map(content => {
        // console.log("title", content.title)
        const item = {
          id: content.id,
          name: content.title,
          children: [],
        }

        if (content.anchorLevel === "h2") {
          if (!menu.lvl) {
            menu = subLvl("h2", content.id)
          }

          menu.items.push(item)
        }
        if (content.anchorLevel === "h3") {
          let last = menu.items.length > 0 ? menu.items.length - 1 : 0
          if (!menu.items[last]?.children.lvl) {
            menu.items[last].children = subLvl("h3")
          }
          menu.items[last].children.items.push(item)
        }

        if (content.anchorLevel === "h4") {
          //console.log("item h4", item)
          let last = menu.items.length > 0 ? menu.items.length - 1 : 0
          let lastH3 =
            menu.items[last].children.items.length > 0
              ? menu.items[last].children.items.length - 1
              : 0

          if (!menu.items[last].children.items[lastH3]?.children.lvl) {
            menu.items[last].children.items[lastH3].children = subLvl("h4")
          }
          menu.items[last].children.items[lastH3].children.items.push(item)
        }
        return false
      })
      stepItem.checklist.map(checklist => {
        checkList.push({
          ...checklist,
          stepIndex: index1,
          pageIndex: index2,
          value: false,
        })
        return false
      })
      return false
    })
    return false
  })

  guide.checkList = checkList.sort((a, b) => {
    return a.guide_checklist_group.weight - b.guide_checklist_group.weight
  })
  // console.log("menu = ", menu)
  guide.menu = menu
  guide.advisor = advisor
  guide.guideFooter = guideFooter
  return guide
}

module.exports = { stepsDTO }
